export default function loopDestroy() {
  const swiper = this;
  const { $wrCarderEl, params, slides } = swiper;
  $wrCarderEl
    .children(
      `.${params.slideClass}.${params.slideDuplicateClass},.${params.slideClass}.${params.slideBlankClass}`,
    )
    .remove();
  slides.removeAttr('data-swiper-slide-index');
}
